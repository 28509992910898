import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "rounded-md bg-red-50 p-4" }
const _hoisted_2 = { class: "flex items-start" }
const _hoisted_3 = { class: "flex-shrink-0" }
const _hoisted_4 = { class: "ml-3" }
const _hoisted_5 = { class: "text-xl font-medium text-red-800 leading-relaxed mt-1" }
const _hoisted_6 = {
  key: 0,
  class: "text-xl text-red-700 pl-8"
}
const _hoisted_7 = {
  type: "disc",
  class: "text-xl font-medium text-red-800 leading-relaxed"
}
const _hoisted_8 = {
  type: "disc",
  class: "text-xl font-medium text-red-800 leading-relaxed"
}
const _hoisted_9 = {
  type: "disc",
  class: "text-xl font-medium text-red-800 leading-relaxed"
}
const _hoisted_10 = {
  type: "disc",
  class: "text-xl font-medium text-red-800 leading-relaxed"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XCircleIcon = _resolveComponent("XCircleIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_XCircleIcon, {
          class: "h-10 w-10 text-red-400",
          "aria-hidden": "true"
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1),
        (
            _ctx.errorMessageTwo ||
            _ctx.errorMessageThree ||
            _ctx.errorMessageFour ||
            _ctx.errorMessageFive
          )
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("ul", null, [
                _createElementVNode("li", _hoisted_7, _toDisplayString(_ctx.errorMessageTwo), 1),
                _createElementVNode("li", _hoisted_8, _toDisplayString(_ctx.errorMessageThree), 1),
                _createElementVNode("li", _hoisted_9, _toDisplayString(_ctx.errorMessageFour), 1),
                _createElementVNode("li", _hoisted_10, _toDisplayString(_ctx.errorMessageFive), 1)
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}