
import { defineComponent, reactive, toRefs, ref, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { newPassword as newPasswordService } from '@/services/aws-cognito.service';
import ErrorDisplay from '@/components/shared/ErrorDisplay.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  components: {
    ErrorDisplay,
  },
  setup() {
    const errorMessageConfirmPass = ref('');
    const isLoading = ref(false);
    const router = useRouter();
    const state = reactive({
      newPasswordForm: {
        email: '',
        oldPassword: '',
        newPassword: '',
      },
    });

    const passwordUpdateRules = computed(() => ({
      newPassword: {
        required,
        minLength: minLength(8),
        goodPassword: (password) => {
          //a custom validator
          return (
            /[a-z]/.test(password) && // password requires lowercase letter
            /[A-Z]/.test(password) && // password requires uppercase letter
            /[0-9]/.test(password) // password requires at least a number
          );
        },
      },
      oldPassword: {
        required,
        minLength: minLength(8),
      },
    }));

    const v$ = useVuelidate(passwordUpdateRules, state.newPasswordForm);

    const handleNewPassword = async () => {
      isLoading.value = true;
      errorMessageConfirmPass.value = '';
      let { oldPassword, newPassword } = state.newPasswordForm;

      newPasswordService(oldPassword, newPassword, (err) => {
        isLoading.value = false;
        if (err) {
          return (errorMessageConfirmPass.value = err?.message
            ? err.message
            : err);
        } else {
          router.push('/login');
        }
      });
    };

    return {
      handleNewPassword,
      isLoading,
      errorMessageConfirmPass,
      v$,
      ...toRefs(state),
    };
  },
});
