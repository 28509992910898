
import { defineComponent } from 'vue';
import { XCircleIcon } from '@heroicons/vue/solid';

export default defineComponent({
  components: {
    XCircleIcon,
  },
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
    errorMessageTwo: {
      type: String,
      default: '',
    },
    errorMessageThree: {
      type: String,
      default: '',
    },
    errorMessageFour: {
      type: String,
      default: '',
    },
    errorMessageFive: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    return {
      props,
    };
  },
});
